<template>
  <div>
    <!-- Form nhập dữ liệu -->
    <div class="input-form">
      <div v-for="(item, idx) in displayedCoins" :key="item.id" class="input-group">
        <v-select  @update:modelValue="handleSelectChange" id="coinSelect" v-model="coinNames[idx]" :options="coins" label="coinName" class="select-point"
          placeholder="Search for a coin..." />
      </div>
    </div>

    <div class="form-group-horizontal">
      <div class="select-form" @change="handleSearch">
        <select v-model="periods">
          <option value="">Chọn thời gian</option>
          <option value="5m">5m</option>
          <option value="1h">1h</option>
          <option value="4h">4h</option>
          <option value="1d">1d</option>
        </select>
      </div>
    </div>

    <!-- Biểu đồ -->
    <div v-for="index in totalChart" :key="'chart-grid-' + index" class="chart-grid">
      <div class="chart-header text-center">
        <h3>
          Biểu đồ
          {{
            typeof arrayCoin[index - 1] === "object"
              ? arrayCoin[index - 1]?.coinName
              : arrayCoin[index - 1]
          }}
        </h3>
      </div>
      <div class="chart-box">
        <div class="chart-container first-chart">
          <canvas :id="chartIds[index - 1]" :ref="'chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container second-chart">
          <canvas :id="secondChartIds[index - 1]" :ref="'second-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container third-chart">
          <canvas :id="thirdChartIds[index - 1]" :ref="'third-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container four-chart">
          <canvas :id="fourChartIds[index - 1]" :ref="'four-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container five-chart">
          <canvas :id="fiveChartIds[index - 1]" :ref="'five-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container six-chart">
          <canvas :id="sixChartIds[index - 1]" :ref="'six-chart' + (index - 1)"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from "../config";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  BarController,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  Filler,
  SubTitle,
} from "chart.js";
import "chartjs-adapter-date-fns";
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(
  CategoryScale,
  SubTitle,
  TimeScale,
  BarController,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend,
  Filler
);
export default {
  components: {
    VSelect,
  },
  data() {
    return {
      selectedCoin: null,
      chartIds: Array.from({ length: 20 }, (_, i) => `chart-${i}`),
      secondChartIds: Array.from({ length: 20 }, (_, i) => `second-chart-${i}`),
      thirdChartIds: Array.from({ length: 20 }, (_, i) => `third-chart-${i}`),
      fourChartIds: Array.from({ length: 20 }, (_, i) => `four-chart-${i}`),
      fiveChartIds: Array.from({ length: 20 }, (_, i) => `five-chart-${i}`),
      sixChartIds: Array.from({ length: 20 }, (_, i) => `six-chart-${i}`),
      coinNames: Array(20).fill(""),
      periods: "",
      charts: [],
      secondCharts: [],
      thirdCharts: [],
      fourCharts: [],
      fiveCharts: [],
      sixCharts: [],
      chartDataSets: [],
      secondChartDataSets: [],
      thirdChartDataSets: [],
      fourChartDataSets: [],
      fiveChartDataSets: [],
      sixChartDataSets: [],
      loading: false,
      coins: [],
      totalChart: 0,
      arrayCoin: [],
      displayedCoins: [],
    };
  },
  async created() {
    await this.fetchCoins();
  },
  methods: {
    async fetchCoins() {
      try {
        const loading = this.$loading.show();
        const response = await fetch(`${API_URL}/contract/get-favorite`);
        const data = await response.json();
        this.coins = data.map((coin) => ({
          id: coin.id,
          coinName: `${coin.coinName}USDT`,
          favoriteFlag: coin.favoriteFlag,
        }));
        const allCoins = this.coins
          .map((coin) => ({ ...coin, isSelected: coin.favoriteFlag === "1" }))
          .sort((a, b) => b.isSelected - a.isSelected);
        this.coinNames = allCoins
          .slice(0, 20)
          .map((coin) => (coin.isSelected ? coin.coinName : ""));
        this.coinNames = [
          ...this.coinNames,
          ...Array(20 - this.coinNames.length).fill(""),
        ];
        this.displayedCoins = allCoins.slice(0, 20);
        loading.hide();
      } catch (error) {
        console.error("Error fetching coins:", error);
      }
    },
    handleSelectChange() {
     if(this.periods != ""){
      setTimeout(() => {
        this.handleSearch();
      }, 1000);
     }
    },
    async handleSearch() {
      console.log(111111111);
      const loading = this.$loading.show();
      const filteredArray = this.coinNames
        .filter((item) => item != null && item !== "")
        .map((item) => (typeof item === "string" ? item : item.coinName));
      this.coinNames = filteredArray;
      let periodMinutes = "1440";

      if (this.periods === "5m") {
        periodMinutes = "5";
      } else if (this.periods === "1h") {
        periodMinutes = "60";
      } else if (this.periods === "4h") {
        periodMinutes = "240";
      }
      for (let i = 0; i < filteredArray.length; i++) {
        this.loading = true;
        await this.fetchNotionalData(this.coinNames[i], i, periodMinutes);
        await this.fetchSecondApiDataForCoin(
          this.coinNames[i],
          i,
          this.periods
        );
        await this.fetchThirdApiDataForCoin(this.coinNames[i], i, this.periods);
        await this.fetchFourApiDataForCoin(this.coinNames[i], i, this.periods);
        await this.fetchFiveApiDataForCoin(this.coinNames[i], i);
        await this.fetchSixApiDataForCoin(this.coinNames[i], i);
      }
      this.totalChart = filteredArray.length;
      this.loading = false;
      this.arrayCoin = this.coinNames;
      this.createCharts();
      loading.hide();
    },

    async fetchNotionalData(coinName, index, period) {
      try {
        const requestBody = {
          name: coinName,
          periodMinutes: period,
        };

        const response = await fetch(
          `${API_URL}/contract/binance/open-interest`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
          }
        );
        const data = await response.json();
        const dataTime = data.data.xAxis;
        const dataValue = data.data.series[1].data;
        const result = dataTime.map((timestamp, index) => ({
          x: timestamp,
          y: dataValue[index],
        }));
        this.chartDataSets[index] = result;
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu từ API mới cho ${coinName}:`,
          error.message || error
        );
      }
    },

    async fetchSecondApiDataForCoin(coinName, index, period) {
      try {
        const requestBody = {
          symbol: coinName,
          period: period,
          limit: 30,
        };

        const response = await fetch(
          `${API_URL}/contract/toplongshort-account`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const result = data
          .map((item) => {
            if (item.timeGet) {
              return {
                x: item.timeGet,
                y: parseFloat(item.longShortRatio),
                longPercent: parseFloat(item.longPercent),
                shortPercent: parseFloat(item.shortPercent),
                longShortRatio: parseFloat(item.longShortRatio),
              };
            }
            return null;
          })
          .filter((item) => item !== null);
        this.secondChartDataSets[index] = result;
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu từ API mới cho ${coinName}:`,
          error.message || error
        );
      }
    },

    async fetchThirdApiDataForCoin(coinName, index, period) {
      try {
        const requestBody = {
          symbol: coinName,
          period: period,
          limit: 30,
        };

        const response = await fetch(
          `${API_URL}/contract/toplongshort-positions`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const result = data
          .map((item) => {
            if (item.timeGet) {
              return {
                x: item.timeGet,
                y: parseFloat(item.longShortRatio),
                longPercent: parseFloat(item.longPercent),
                shortPercent: parseFloat(item.shortPercent),
                longShortRatio: parseFloat(item.longShortRatio),
              };
            }
            return null;
          })
          .filter((item) => item !== null);

        this.thirdChartDataSets[index] = result;
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu từ API thứ ba cho ${coinName}:`,
          error.message || error
        );
      }
    },
    async fetchFourApiDataForCoin(coinName, index, period) {
      try {
        const requestBody = {
          symbol: coinName,
          period: period,
          limit: 30,
        };

        const response = await fetch(`${API_URL}/contract/longshort`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const result = data
          .map((item) => {
            if (item.timeGet) {
              return {
                x: item.timeGet,
                y: parseFloat(item.longShortRatio),
                longPercent: parseFloat(item.longPercent),
                shortPercent: parseFloat(item.shortPercent),
                longShortRatio: parseFloat(item.longShortRatio),
              };
            }
            return null;
          })
          .filter((item) => item !== null);

        this.fourChartDataSets[index] = result;
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu từ API thứ ba cho ${coinName}:`,
          error.message || error
        );
      }
    },
    async fetchFiveApiDataForCoin(coinName, index) {
      try {
        const requestBody = {
          symbol: coinName,
          groupCount: "7",
          groupSize: "24",
          period: "HOUR_1",
          volumeType: "BIG",
        };

        const response = await fetch(`${API_URL}/binance/day-large-inflow`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });
        const dataResult = await response.json();

        const data = dataResult.data;

        const result = data.netCapitalInList
          .map((item) => {
            return parseFloat(item.netCapitalInflowVolume);
          })
          .filter((item) => item !== null);
        const dataReturn = {
          dataSet: result,
          totalInFlow: data.totalInFlow,
        };
        this.fiveChartDataSets[index] = dataReturn;
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu từ API thứ ba cho ${coinName}:`,
          error.message || error
        );
      }
    },
    async fetchSixApiDataForCoin(coinName, index) {
      try {
        const requestBody = {
          symbol: coinName,
          intervalEnum: "FIVE_MINUTES",
          size: "288",
        };

        const response = await fetch(`${API_URL}/binance/hour-large-inflow`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        console.log(data);
        const result = data.data
          .map((item) => {
            if (item.startTime) {
              return {
                x: item.startTime,
                y: parseFloat(item.netCapitalInflowVolume),
              };
            }
            return null;
          })
          .filter((item) => item !== null);

        this.sixChartDataSets[index] = result;
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu từ API thứ ba cho ${coinName}:`,
          error.message || error
        );
      }
    },
    createCharts() {
      this.$nextTick(() => {
        let periods = this.periods;
        this.chartIds.slice(0, this.totalChart).forEach((chartId, index) => {
          const canvas = document.getElementById(chartId);
          if (canvas) {
            const ctx = canvas.getContext("2d");
            if (this.charts[index]) {
              this.charts[index].destroy();
            }
            const dataSet = this.chartDataSets[index] || [];
            this.charts[index] = new ChartJS(ctx, {
              type: "line",
              data: {
                datasets: [
                  {
                    label: `Giá trị bề mặt của các khoản lệnh đang mở`,
                    data: dataSet,
                    borderColor: "#3498db",
                    backgroundColor: "rgba(52, 152, 219, 0.5)",
                    fill: true,
                    tension: 0,
                    borderWidth: 2,
                  },
                ],
              },
              options: {
                plugins: {
                  legend: {
                    display: true,
                  },
                  datalabels: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: `Open Interest(${this.coinNames[index]})`,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                interaction: {
                  mode: "index",
                  intersect: false,
                },
                scales: {
                  x: {
                    type: "time",
                    time: {
                      ...(this.periods === "1d" && {
                        round: "day",
                      }),
                      unit:
                        this.periods === "1h" || this.periods === "4h"
                          ? "hour"
                          : this.periods === "1d"
                            ? "day"
                            : "minute",
                    },
                    labels: dataSet.map((item) => item.x),
                    ticks: {
                      maxTicksLimit: 30,
                      ...(this.periods !== "1d" && {
                        stepSize:
                          this.periods === "1h"
                            ? 1
                            : this.periods === "4h"
                              ? 4
                              : 5,
                      }),
                      callback: function (value) {
                        const date = new Date(value);
                        if (periods === "1d") {
                          return date.toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                          });
                        } else {
                          return date.toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                          });
                        }
                      },
                    },
                    stacked: true,
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
              },
            });
          }
        });

        this.secondChartIds
          .slice(0, this.totalChart)
          .forEach((chartId, index) => {
            const canvas = document.getElementById(chartId);
            if (canvas) {
              const ctx = canvas.getContext("2d");
              if (this.secondCharts[index]) {
                this.secondCharts[index].destroy();
              }

              const dataSet = this.secondChartDataSets[index] || [];
              const minValue = Math.min(
                ...dataSet.map((item) => item.longShortRatio)
              );
              const roundedValue = Math.floor(minValue / 0.05) * 0.05;
              const startValue =
                minValue - roundedValue <= 0.05
                  ? roundedValue
                  : roundedValue + 0.05;

              this.secondCharts[index] = new ChartJS(ctx, {
                type: "bar",
                data: {
                  datasets: [
                    {
                      label: "Tỷ lệ Long/Short (Tài khoản)",
                      type: "line",
                      data: dataSet,
                      borderColor: "rgba(0, 0, 0, 1)",
                      pointBackgroundColor: "rgba(255, 255, 255, 1)",
                      pointBorderColor: "rgba(0, 0, 0, 1)",
                      pointBorderWidth: 1.5,
                      pointRadius: 4,
                      pointHoverRadius: 6,
                      fill: true,
                      tension: 0.4,
                      borderWidth: 2,
                      yAxisID: "y-line",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Tài khoản Long",
                      data: dataSet.map((item) => item.longPercent),
                      backgroundColor: "rgba(0, 209, 112, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Tài khoản Short",
                      data: dataSet.map((item) => item.shortPercent),
                      backgroundColor: "rgba(231, 76, 60, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                    },
                    datalabels: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: `Top Long Short Account(${this.coinNames[index]})`,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const dataset = tooltipItem.dataset;
                          const rawData = dataset.meta.customValue;
                          const index = tooltipItem.dataIndex;
                          const shortPercent = rawData[index].shortPercent || 0;
                          const longPercent = rawData[index].longPercent || 0;
                          const longShortRatio =
                            rawData[index].longShortRatio || 0;
                          return `Long: ${longPercent}%\r\nShort: ${shortPercent}%\r\nLong Short Ratio: ${longShortRatio}`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        ...(this.periods === "1d" && {
                          round: "day",
                        }),
                        unit:
                          this.periods === "1h" || this.periods === "4h"
                            ? "hour"
                            : this.periods === "1d"
                              ? "day"
                              : "minute",
                      },
                      labels: dataSet.map((item) => item.x),
                      ticks: {
                        maxTicksLimit: 30,
                        ...(this.periods !== "1d" && {
                          stepSize:
                            this.periods === "1h"
                              ? 1
                              : this.periods === "4h"
                                ? 4
                                : 5,
                        }),
                        callback: function (value) {
                          const date = new Date(value);
                          if (periods === "1d") {
                            return date.toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                            });
                          } else {
                            return date.toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                            });
                          }
                        },
                      },
                      stacked: true,
                    },
                    "y-line": {
                      id: "y-line",
                      position: "right",
                      grid: {
                        drawOnChartArea: false,
                      },
                      beginAtZero: false,
                      min: startValue,
                      ticks: {
                        callback: function (value) {
                          return value.toFixed(2);
                        },
                      },
                    },
                    y: {
                      display: false,
                      grid: {
                        display: false,
                      },
                    },
                  },
                },
              });
            }
          });

        this.thirdChartIds
          .slice(0, this.totalChart)
          .forEach((chartId, index) => {
            const canvas = document.getElementById(chartId);
            if (canvas) {
              const ctx = canvas.getContext("2d");
              if (this.thirdCharts[index]) {
                this.thirdCharts[index].destroy();
              }

              const dataSet = this.thirdChartDataSets[index] || [];
              const minValue = Math.min(
                ...dataSet.map((item) => item.longShortRatio)
              );
              const roundedValue = Math.floor(minValue / 0.05) * 0.05;
              const startValue =
                minValue - roundedValue <= 0.05
                  ? roundedValue
                  : roundedValue + 0.05;

              this.thirdCharts[index] = new ChartJS(ctx, {
                type: "bar",
                data: {
                  datasets: [
                    {
                      label: "Tỷ lệ Long/Short (Vị thế)",
                      type: "line",
                      data: dataSet,
                      borderColor: "rgba(0, 0, 0, 1)",
                      pointBackgroundColor: "rgba(255, 255, 255, 1)",
                      pointBorderColor: "rgba(0, 0, 0, 1)",
                      pointBorderWidth: 1.5,
                      pointRadius: 4,
                      pointHoverRadius: 6,
                      fill: true,
                      tension: 0.4,
                      borderWidth: 2,
                      yAxisID: "y-line",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Vị thế Long",
                      data: dataSet.map((item) => item.longPercent),
                      backgroundColor: "rgba(0, 209, 112, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Vị thế Short",
                      data: dataSet.map((item) => item.shortPercent),
                      backgroundColor: "rgba(231, 76, 60, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                    },
                    datalabels: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: `Top Long Short Positions(${this.coinNames[index]})`,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const dataset = tooltipItem.dataset;
                          const rawData = dataset.meta.customValue;
                          const index = tooltipItem.dataIndex;
                          const shortPercent = rawData[index].shortPercent || 0;
                          const longPercent = rawData[index].longPercent || 0;
                          const longShortRatio =
                            rawData[index].longShortRatio || 0;
                          return `Long: ${longPercent}%\r\nShort: ${shortPercent}%\r\nLong Short Ratio: ${longShortRatio}`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        ...(this.periods === "1d" && {
                          round: "day",
                        }),
                        unit:
                          this.periods === "1h" || this.periods === "4h"
                            ? "hour"
                            : this.periods === "1d"
                              ? "day"
                              : "minute",
                      },
                      labels: dataSet.map((item) => item.x),
                      ticks: {
                        maxTicksLimit: 30,
                        ...(this.periods !== "1d" && {
                          stepSize:
                            this.periods === "1h"
                              ? 1
                              : this.periods === "4h"
                                ? 4
                                : 5,
                        }),
                        callback: function (value) {
                          const date = new Date(value);
                          if (periods === "1d") {
                            return date.toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                            });
                          } else {
                            return date.toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                            });
                          }
                        },
                      },
                      stacked: true,
                    },
                    "y-line": {
                      id: "y-line",
                      position: "right",
                      grid: {
                        drawOnChartArea: false,
                      },
                      beginAtZero: false,
                      min: startValue,
                      ticks: {
                        callback: function (value) {
                          return value.toFixed(2);
                        },
                      },
                    },
                    y: {
                      display: false,
                      grid: {
                        display: false,
                      },
                    },
                  },
                },
              });
            }
          });
        this.fourChartIds
          .slice(0, this.totalChart)
          .forEach((chartId, index) => {
            const canvas = document.getElementById(chartId);
            if (canvas) {
              const ctx = canvas.getContext("2d");
              if (this.fourCharts[index]) {
                this.fourCharts[index].destroy();
              }

              const dataSet = this.fourChartDataSets[index] || [];
              const minValue = Math.min(
                ...dataSet.map((item) => item.longShortRatio)
              );
              const roundedValue = Math.floor(minValue / 0.05) * 0.05;
              const startValue =
                minValue - roundedValue <= 0.05
                  ? roundedValue
                  : roundedValue + 0.05;
              this.fourCharts[index] = new ChartJS(ctx, {
                type: "bar",
                data: {
                  datasets: [
                    {
                      label: "Tỷ lệ Long/Short",
                      type: "line",
                      data: dataSet,
                      borderColor: "rgba(0, 0, 0, 1)",
                      pointBackgroundColor: "rgba(255, 255, 255, 1)",
                      pointBorderColor: "rgba(0, 0, 0, 1)",
                      pointBorderWidth: 1.5,
                      pointRadius: 4,
                      pointHoverRadius: 6,
                      fill: true,
                      tension: 0.4,
                      borderWidth: 2,
                      yAxisID: "y-line",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Tài khoản Long",
                      data: dataSet.map((item) => item.longPercent),
                      backgroundColor: "rgba(0, 209, 112, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Tài khoản Short",
                      data: dataSet.map((item) => item.shortPercent),
                      backgroundColor: "rgba(231, 76, 60, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,

                  plugins: {
                    datalabels: {
                      display: false,
                    },

                    legend: {
                      display: true,
                    },
                    title: {
                      display: true,
                      text: `Long/Short ratio(${this.coinNames[index]})`,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const dataset = tooltipItem.dataset;
                          const rawData = dataset.meta.customValue;
                          const index = tooltipItem.dataIndex;
                          const shortPercent = rawData[index].shortPercent || 0;
                          const longPercent = rawData[index].longPercent || 0;
                          const longShortRatio =
                            rawData[index].longShortRatio || 0;
                          return `Long: ${longPercent}%\r\nShort: ${shortPercent}%\r\nLong Short Ratio: ${longShortRatio}`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        ...(this.periods === "1d" && {
                          round: "day",
                        }),
                        unit:
                          this.periods === "1h" || this.periods === "4h"
                            ? "hour"
                            : this.periods === "1d"
                              ? "day"
                              : "minute",
                      },
                      labels: dataSet.map((item) => item.x),
                      ticks: {
                        maxTicksLimit: 30,
                        ...(this.periods !== "1d" && {
                          stepSize:
                            this.periods === "1h"
                              ? 1
                              : this.periods === "4h"
                                ? 4
                                : 5,
                        }),
                        callback: function (value) {
                          const date = new Date(value);
                          if (periods === "1d") {
                            return date.toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                            });
                          } else {
                            return date.toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                            });
                          }
                        },
                      },
                      stacked: true,
                    },

                    "y-line": {
                      position: "right",
                      beginAtZero: false,
                      min: startValue,
                      ticks: {
                        callback: function (value) {
                          return value.toFixed(2);
                        },
                      },
                    },

                    y: {
                      display: false,
                      grid: {
                        display: false,
                      },
                      ticks: {
                        beginAtZero: false,
                        callback: function (value) {
                          return value.toFixed(2);
                        },
                      },
                    },
                  },
                },
              });
            }
          });
        this.fiveChartIds
          .slice(0, this.totalChart)
          .forEach((chartId, index) => {
            const canvas = document.getElementById(chartId);
            if (canvas) {
              const ctx = canvas.getContext("2d");
              if (this.fiveCharts[index]) {
                this.fiveCharts[index].destroy();
              }
              const dataGet = this.fiveChartDataSets[index] || [];
              const totalInFlow = Math.abs(dataGet.totalInFlow);
              let dataTotalInFlow  = 0;
              if (totalInFlow >= 1000000000) {
                 dataTotalInFlow  = (totalInFlow / 1000000000).toFixed(2) + "B";
              } else if (totalInFlow >= 1000000) {
                 dataTotalInFlow  = (totalInFlow / 1000000).toFixed(2) + "M";
              } else if (totalInFlow >= 1000) {
                 dataTotalInFlow  = (totalInFlow / 1000).toFixed(2) + "K";
              }
              const dataSet = dataGet.dataSet;
              this.fiveCharts[index] = new ChartJS(ctx, {
                type: "bar",
                data: {
                  labels: [1, 2, 3, 4, 5, 6, 7],
                  datasets: [
                    {
                      label: `7 days large inflow: ${dataTotalInFlow}`,
                      data: dataSet,
                      backgroundColor: dataSet.map((value) =>
                        value < 0
                          ? "rgba(255, 0, 0, 0.6)"
                          : "rgba(0, 128, 0, 0.6)"
                      ),
                      borderColor: dataSet.map((value) =>
                        value < 0 ? "rgb(255, 0, 0)" : "rgb(0, 128, 0)"
                      ),
                      borderWidth: 1,
                    },
                  ],
                },
                options: {
                  maintainAspectRatio: false,
                  tooltips: { enabled: false },
                  hover: { mode: null },
                  responsive: true,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: true,
                      ticks: {
                        callback: function (value) {
                          const absValue = Math.abs(value);
                          if (absValue >= 1000000000) {
                            return (absValue / 1000000000).toFixed(2) + "B";
                          } else if (absValue >= 1000000) {
                            return (absValue / 1000000).toFixed(2) + "M";
                          } else if (absValue >= 1000) {
                            return (absValue / 1000).toFixed(2) + "K";
                          }
                          return absValue;
                        },
                      },
                    },
                  },
                  plugins: {
                    subtitle: {
                      display: true,
                      text: `7 days large inflow: ${dataTotalInFlow}`,
                      font: {
                        style: "italic",
                      },
                      color: dataGet.totalInFlow < 0 ? "red" : "green",
                      padding: 20,
                    },
                    title: {
                      display: true,
                      text: `24hr Large Inflow(${this.coinNames[index]})`,
                    },

                    tooltip: {
                      enabled: false,
                      callbacks: {
                        label: function (context) {
                          const value = context.raw; // Giá trị của điểm dữ liệu
                          // Chỉnh sửa cách hiển thị label trong tooltip
                          const absValue = Math.abs(value);
                          let formattedValue = value;
                          if (absValue >= 1000000000) {
                            formattedValue = (value / 1000000000).toFixed(2) + "B"; // Tỉ
                          } else if (absValue >= 1000000) {
                            formattedValue = (value / 1000000).toFixed(2) + "M"; // Triệu
                          } else if (absValue >= 1000) {
                            formattedValue = (value / 1000).toFixed(2) + "K"; // Nghìn
                          }
                          return `${context.dataset.label}: ${formattedValue}`;
                        }
                      }
                    },
                    legend: {
                      display: false,
                    },
                    datalabels: {
                      display: true,
                      align: (context) => {
                        return context.dataset.data[context.dataIndex] < 0
                          ? "start"
                          : "end";
                      },
                      anchor: (context) => {
                        return context.dataset.data[context.dataIndex] < 0
                          ? "start"
                          : "end";
                      },
                      color: (context) => {
                        return context.dataset.data[context.dataIndex] < 0
                          ? "red"
                          : "green";
                      },
                      font: {
                        weight: "bold",
                        size: 10,
                      },
                      formatter: function (value) {
                        const absValue = Math.abs(value);
                        if (absValue >= 1000000000) {
                          return (value / 1000000000).toFixed(2) + "B";
                        } else if (absValue >= 1000000) {
                          return (value / 1000000).toFixed(2) + "M";
                        } else if (absValue >= 1000) {
                          return (value / 1000).toFixed(2) + "K";
                        }
                        return value;
                      },
                    },
                  },
                },
              });
            }
          });
        this.sixChartIds.slice(0, this.totalChart).forEach((chartId, index) => {
          const canvas = document.getElementById(chartId);
          if (canvas) {
            const ctx = canvas.getContext("2d");
            if (this.sixCharts[index]) {
              this.sixCharts[index].destroy();
            }
            const dataSet = this.sixChartDataSets[index] || [];
            console.log(dataSet);
            this.sixCharts[index] = new ChartJS(ctx, {
              type: "line",
              data: {
                datasets: [
                  {
                    data: dataSet,
                    borderColor: "#f39c12",
                    backgroundColor: "rgba(243, 156, 18, 0.5)",
                    fill: true,
                    tension: 0,
                    borderWidth: 2,
                    pointRadius: 0,
                  },
                ],
              },
              options: {
                interaction: {
                  mode: "index",
                  intersect: false,
                },
                plugins: {
                  datalabels: {
                    display: false,
                  },
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: `24hr Large Inflow(${this.coinNames[index]})`,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                scales: {
                  x: {
                    type: "time",
                    grid: {
                      display: false,
                    },
                    time: {
                      unit: "hour",
                    },
                    labels: dataSet.map((item) => item.x),
                    ticks: {
                      maxTicksLimit: 30,
                      stepSize: 6,
                      callback: function (value) {
                        const date = new Date(value);
                        const time = date.toLocaleTimeString("en-GB", {
                          hour: "2-digit",
                          minute: "2-digit",
                        });
                        return time;
                      },
                    },
                    stacked: true,
                  },
                  y: {
                    display: true,
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
              },
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.form-group-horizontal {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-form select,
.search-button button {
  padding: 5px 5px;
  font-size: 12px;
}

.input-form {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.input-form:hover,
.select-point:hover {
  cursor: pointer;
}

.input-group {
  margin-bottom: 10px;
}

.input-group input {
  width: 80%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.select-form {
  margin-bottom: 10px;
  width: 100px;
}

select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 12px;
  width: 100%;
  max-width: 100px;
  margin-top: 10px;
}

button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

.chart-grid {
  margin: 0 auto;
  width: 100%;
}

.chart-grid .chart-box {
  border: 2px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.chart-container {
  flex: 1 1 calc(50% - 40px);
  max-width: calc(50% - 40px);
  min-width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-chart,
.second-chart,
.third-chart,
.four-chart,
.five-chart {
  width: 100%;
}

.chart-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.chart-header h3 {
  font-size: 20px;
  margin: 0;
  color: #333;
}
</style>
<style>
#coinSelect.vs--searchable .vs__dropdown-toggle {
  height: 27px !important;
}

.vs__dropdown-menu {
  font-size: 12px;
}

.vs__selected {
  font-size: 12px;
}

.vs__search {
  font-size: 12px;
}
</style>
