<template>
  <div>
    <!-- Form nhập dữ liệu -->
    <div class="input-form">
      <div v-for="(item, idx) in displayedCoins" :key="item.id" class="input-group">
        <v-select id="coinSelect" v-model="coinNames[idx]" :options="coins" label="coinName" class="select-point"
          placeholder="Search for a coin..." @update:modelValue="handleSelectChange"  />
      </div>
    </div>

    <div class="form-group-horizontal">
      <div class="select-form" @change="handleSearch">
        <select v-model="periods">
          <option value="">Chọn thời gian</option>
          <option value="H1">1H</option>
          <option value="H2">2H</option>
          <option value="H4">4H</option>
          <option value="D1">1D</option>
        </select>
      </div>
    </div>

    <!-- Biểu đồ -->
    <div v-for="index in totalChart" :key="'chart-grid-' + index" class="chart-grid">
      <div class="chart-header text-center">
        <h3>
          Biểu đồ
          {{
        typeof arrayCoin[index - 1] === "object"
          ? arrayCoin[index - 1]?.coinName
          : arrayCoin[index - 1]
      }}
        </h3>
      </div>
      <div class="chart-box">
        <div class="chart-container first-chart">
          <canvas :id="chartIds[index - 1]" :ref="'chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container second-chart">
          <canvas :id="secondChartIds[index - 1]" :ref="'second-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container third-chart">
          <canvas :id="thirdChartIds[index - 1]" :ref="'third-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container four-chart">
          <canvas :id="fourChartIds[index - 1]" :ref="'four-chart' + (index - 1)"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from "../config";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  BarController,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import "chartjs-adapter-date-fns";
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";

ChartJS.register(
  CategoryScale,
  TimeScale,
  BarController,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      selectedCoin: null,
      chartIds: Array.from({ length: 20 }, (_, i) => `chart-${i}`),
      secondChartIds: Array.from({ length: 20 }, (_, i) => `second-chart-${i}`),
      thirdChartIds: Array.from({ length: 20 }, (_, i) => `third-chart-${i}`),
      fourChartIds: Array.from({ length: 20 }, (_, i) => `four-chart-${i}`),
      coinNames: Array(20).fill(""),
      periods: "",
      charts: [],
      secondCharts: [],
      thirdCharts: [],
      fourCharts: [],
      chartDataSets: [],
      secondChartDataSets: [],
      thirdChartDataSets: [],
      fourChartDataSets: [],
      loading: false,
      coins: [],
      totalChart: 0,
      arrayCoin: [],
      displayedCoins: [],
    };
  },
  async created() {
    await this.fetchCoins();
  },
  methods: {
    async fetchCoins() {
      try {
        const loading = this.$loading.show();
        const response = await fetch(`${API_URL}/futures/mexc/get-favorite`);
        const data = await response.json();
        this.coins = data.map((coin) => ({
          id: coin.id,
          coinName: `${coin.coinName}`,
          favoriteFlag: coin.favoriteFlag,
        }));
        const allCoins = this.coins
          .map((coin) => ({ ...coin, isSelected: coin.favoriteFlag === "1" }))
          .sort((a, b) => b.isSelected - a.isSelected);
        this.coinNames = allCoins
          .slice(0, 20)
          .map((coin) => (coin.isSelected ? coin.coinName : ""));
        this.coinNames = [
          ...this.coinNames,
          ...Array(20 - this.coinNames.length).fill(""),
        ];
        this.displayedCoins = allCoins.slice(0, 20);
        loading.hide();
      } catch (error) {
        console.error("Error fetching coins:", error);
      }
    },
    handleSelectChange() {
     if(this.periods != ""){
      setTimeout(() => {
        this.handleSearch();
      }, 1000);
     }
    },
    async handleSearch() {
      const loading = this.$loading.show();
      const filteredArray = this.coinNames
        .filter((item) => item != null && item !== "")
        .map((item) => (typeof item === "string" ? item : item.coinName));
      this.coinNames = filteredArray;
      for (let i = 0; i < filteredArray.length; i++) {
        this.loading = true;
        const dataCoin = await this.fetchNotionalData(
          this.coinNames[i],
          this.periods
        );
        this.chartDataSets[i] = dataCoin;
        await this.fetchSecondApiDataForCoin(
          this.coinNames[i],
          i,
          this.periods
        );
        await this.fetchThirdApiDataForCoin(this.coinNames[i], i, this.periods);
        await this.fetchFourApiDataForCoin(this.coinNames[i], i, this.periods);
      }
      this.totalChart = filteredArray.length;
      this.loading = false;
      this.arrayCoin = this.coinNames;
      this.createCharts();
      loading.hide();
    },

    async fetchNotionalData(coinName, period) {
      try {
        let periodData = "1d";
        if (period === 'H1') {
          periodData = '1h';
        }else if (period === 'H2') {
          periodData = '2h';
        }else if (period === 'H4') {
          periodData = '4h';
        }else{
          periodData = '1d';
        }
        const requestBody = {
          symbol: coinName,
          period: periodData,
        };
        const response = await fetch(`${API_URL}/mexc/openinterest`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });  

        const data = await response.json();
        const convertData = data.map((item) => {
          const [datePart, timePart] = item.time.split(" ");
          const [year, month, day] = datePart.split("-").map(Number);
          const [hour, minute] = timePart.split(":").map(Number);
          const timestamp = new Date(year, month - 1, day, hour, minute).getTime();
          const openInterestValue = parseFloat(item.openInterest.replace(/,/g, ""));

          return { x: timestamp, y: openInterestValue };
        });

        return convertData.sort((a, b) => a.x - b.x);
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu cho ${coinName}:`,
          error.message || error
        );
        return [];
      }
    },

    async fetchSecondApiDataForCoin(coinName, index, period) {
      try {
        const requestBody = {
          symbol: coinName,
          currency: 'USDT',
          interval: period,
          type: 'PLATFORM_ELITE_TOTAL_AMOUNT',
        };

        const response = await fetch(
          `${API_URL}/futures/position`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
          }
        );
        const dataResponse = await response.json();

        if (!dataResponse.success) {
          throw new Error(`HTTP error! Status: ${response.code}`);
        }
        const data = dataResponse.data;
        const result = data.time.map((time, index) => {
          const longPercent = data.longRatio[index] * 100;
          const shortPercent = data.shortRatio[index] * 100;

          return {
            x: time,
            y: parseFloat(data.longShortRatio[index].toFixed(2)),
            longPercent: parseFloat(longPercent.toFixed(2)),
            shortPercent: parseFloat(shortPercent.toFixed(2)),
            longShortRatio: parseFloat(data.longShortRatio[index].toFixed(2)),
          };
        });

        this.secondChartDataSets[index] = result;
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu từ API mới cho ${coinName}:`,
          error.message || error
        );
      }
    },

    async fetchThirdApiDataForCoin(coinName, index, period) {
      try {
        const requestBody = {
          symbol: coinName,
          currency: 'USDT',
          interval: period,
          type: 'PLATFORM_TOTAL_USERS',
        };

        const response = await fetch(
          `${API_URL}/futures/position`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
          }
        );
        const dataResponse = await response.json();

        if (!dataResponse.success) {
          throw new Error(`HTTP error! Status: ${response.code}`);
        }
        const data = dataResponse.data;
        const result = data.time.map((time, index) => {
          const longPercent = data.longRatio[index] * 100;
          const shortPercent = data.shortRatio[index] * 100;

          return {
            x: time,
            y: parseFloat(data.longShortRatio[index].toFixed(2)),
            longPercent: parseFloat(longPercent.toFixed(2)),
            shortPercent: parseFloat(shortPercent.toFixed(2)),
            longShortRatio: parseFloat(data.longShortRatio[index].toFixed(2)),
          };
        });

        this.thirdChartDataSets[index] = result;
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu từ API thứ ba cho ${coinName}:`,
          error.message || error
        );
      }
    },
    async fetchFourApiDataForCoin(coinName, index, period) {
      try {
        const requestBody = {
          symbol: coinName,
          currency: 'USDT',
          interval: period,
          type: 'PLATFORM_ELITE_TOTAL_USERS',
        };

        const response = await fetch(
          `${API_URL}/futures/position`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
          }
        );
        const dataResponse = await response.json();

        if (!dataResponse.success) {
          throw new Error(`HTTP error! Status: ${response.code}`);
        }
        const data = dataResponse.data;
        const result = data.time.map((time, index) => {
          const longPercent = data.longRatio[index] * 100;
          const shortPercent = data.shortRatio[index] * 100;

          return {
            x: time,
            y: parseFloat(data.longShortRatio[index].toFixed(2)),
            longPercent: parseFloat(longPercent.toFixed(2)),
            shortPercent: parseFloat(shortPercent.toFixed(2)),
            longShortRatio: parseFloat(data.longShortRatio[index].toFixed(2)),
          };
        });
        this.fourChartDataSets[index] = result;
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu từ API thứ ba cho ${coinName}:`,
          error.message || error
        );
      }
    },
    createCharts() {
      this.$nextTick(() => {
        let periods = this.periods;
        this.chartIds.slice(0, this.totalChart).forEach((chartId, index) => {
          const canvas = document.getElementById(chartId);
          if (canvas) {
            const ctx = canvas.getContext("2d");
            if (this.charts[index]) {
              this.charts[index].destroy();
            }

            const dataSet = this.chartDataSets[index] || [];

            this.charts[index] = new ChartJS(ctx, {
              type: "line",
              data: {
                datasets: [
                  {
                    label: `Giá trị bề mặt của các khoản lệnh đang mở`,
                    data: dataSet,
                    borderColor: "#3498db",
                    backgroundColor: "rgba(52, 152, 219, 0.5)",
                    fill: true,
                    tension: 0,
                    borderWidth: 2,
                  },
                ],
              },
              options: {
                plugins: {
                  legend: {
                    display: true,
                  },
                  datalabels: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: `Open Interest(${this.coinNames[index]})`,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                interaction: {
                  mode: "index",
                  intersect: false,
                },
                scales: {
                  x: {
                    type: "time",
                    time: {
                      ...(this.periods === "D1" && {
                        round: "day",
                      }),
                      unit:
                        this.periods === "H1" || this.periods === "H4" || this.periods === "H2"
                          ? "hour"
                          : this.periods === "D1"
                            ? "day"
                            : "minute",
                    },
                    labels: dataSet.map((item) => item.x),
                    ticks: {
                      maxTicksLimit: 30,
                      ...(this.periods !== "D1" && {
                        stepSize:
                          this.periods === "D1"
                            ? 1
                            : this.periods === "H4"
                            ? 4
                            : this.periods === "H2"
                              ? 2
                              : 1,
                      }),
                      callback: function (value) {
                        const date = new Date(value);
                        if (periods === "D1") {
                          return date.toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                          });
                        } else {
                          return date.toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                          });
                        }
                      },
                    },
                    stacked: true,
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
              },
            });
          }
        });

        this.secondChartIds
          .slice(0, this.totalChart)
          .forEach((chartId, index) => {
            const canvas = document.getElementById(chartId);
            if (canvas) {
              const ctx = canvas.getContext("2d");
              if (this.secondCharts[index]) {
                this.secondCharts[index].destroy();
              }

              const dataSet = this.secondChartDataSets[index] || [];
              const minValue = Math.min(
                ...dataSet.map((item) => item.longShortRatio)
              );
              const roundedValue = Math.floor(minValue / 0.05) * 0.05;
              const startValue =
                minValue - roundedValue <= 0.05
                  ? roundedValue - 0.5
                  : roundedValue + 0.5;

              this.secondCharts[index] = new ChartJS(ctx, {
                type: "bar",
                data: {
                  datasets: [
                    {
                      label: "Long holding / Short holding",
                      type: "line",
                      data: dataSet,
                      borderColor: "rgba(0, 0, 0, 1)",
                      pointBackgroundColor: "rgba(255, 255, 255, 1)",
                      pointBorderColor: "rgba(0, 0, 0, 1)",
                      pointBorderWidth: 1.5,
                      pointRadius: 4,
                      pointHoverRadius: 6,
                      fill: true,
                      tension: 0.4,
                      borderWidth: 2,
                      yAxisID: "y-line",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Long holding ratio",
                      data: dataSet.map((item) => item.longPercent),
                      backgroundColor: "rgba(0, 209, 112, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Short holding ratio",
                      data: dataSet.map((item) => item.shortPercent),
                      backgroundColor: "rgba(231, 76, 60, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                    },
                    datalabels: {
                      display: false
                    },
                    title: {
                      display: true,
                      text: `Elite Account Long-Short Position Holding Ratio(${this.coinNames[index]})`,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const dataset = tooltipItem.dataset;
                          const rawData = dataset.meta.customValue;
                          const index = tooltipItem.dataIndex;
                          const shortPercent = rawData[index].shortPercent || 0;
                          const longPercent = rawData[index].longPercent || 0;
                          const longShortRatio =
                            rawData[index].longShortRatio || 0;
                          return `Long: ${longPercent}%\r\nShort: ${shortPercent}%\r\nLong Short Ratio: ${longShortRatio}`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        ...(this.periods === "D1" && {
                          round: "day",
                        }),
                        unit:
                          this.periods === "H1" || this.periods === "H4" || this.periods === "H2"
                            ? "hour"
                            : this.periods === "D1"
                              ? "day"
                              : "minute",
                      },
                      labels: dataSet.map((item) => item.x),
                      ticks: {
                        maxTicksLimit: 30,
                        ...(this.periods !== "D1" && {
                          stepSize:
                            this.periods === "H1"
                              ? 1
                              : this.periods === "H4"
                                ? 4
                                : 2,
                        }),
                        callback: function (value) {
                          const date = new Date(value);
                          if (periods === "D1") {
                            return date.toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                            });
                          } else {
                            return date.toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                            });
                          }
                        },
                      },
                      stacked: true,
                    },
                    "y-line": {
                      id: "y-line",
                      position: "right",
                      grid: {
                        drawOnChartArea: false,
                      },
                      beginAtZero: false,
                      min: startValue,
                      ticks: {
                        callback: function (value) {
                          return value.toFixed(2);
                        },
                      },
                    },
                    y: {
                      display: false,
                      grid: {
                        display: false,
                      },
                    },
                  },
                },
              });
            }
          });

        this.thirdChartIds
          .slice(0, this.totalChart)
          .forEach((chartId, index) => {
            const canvas = document.getElementById(chartId);
            if (canvas) {
              const ctx = canvas.getContext("2d");
              if (this.thirdCharts[index]) {
                this.thirdCharts[index].destroy();
              }

              const dataSet = this.thirdChartDataSets[index] || [];
              const minValue = Math.min(
                ...dataSet.map((item) => item.longShortRatio)
              );
              const roundedValue = Math.floor(minValue / 0.05) * 0.05;
              const startValue =
                minValue - roundedValue <= 0.05
                  ? roundedValue - 0.5
                  : roundedValue + 0.5;

              this.thirdCharts[index] = new ChartJS(ctx, {
                type: "bar",
                data: {
                  datasets: [
                    {
                      label: "Long holding / Short holding",
                      type: "line",
                      data: dataSet,
                      borderColor: "rgba(0, 0, 0, 1)",
                      pointBackgroundColor: "rgba(255, 255, 255, 1)",
                      pointBorderColor: "rgba(0, 0, 0, 1)",
                      pointBorderWidth: 1.5,
                      pointRadius: 4,
                      pointHoverRadius: 6,
                      fill: true,
                      tension: 0.4,
                      borderWidth: 2,
                      yAxisID: "y-line",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Long holding ratio",
                      data: dataSet.map((item) => item.longPercent),
                      backgroundColor: "rgba(0, 209, 112, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Short holding ratio",
                      data: dataSet.map((item) => item.shortPercent),
                      backgroundColor: "rgba(231, 76, 60, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                    },
                    datalabels: {
                      display: false
                    },
                    title: {
                      display: true,
                      text: `Platform's Long-Short Position Holders Ratio(${this.coinNames[index]})`,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const dataset = tooltipItem.dataset;
                          const rawData = dataset.meta.customValue;
                          const index = tooltipItem.dataIndex;
                          const shortPercent = rawData[index].shortPercent || 0;
                          const longPercent = rawData[index].longPercent || 0;
                          const longShortRatio =
                            rawData[index].longShortRatio || 0;
                          return `Long: ${longPercent}%\r\nShort: ${shortPercent}%\r\nLong Short Ratio: ${longShortRatio}`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        ...(this.periods === "D1" && {
                          round: "day",
                        }),
                        unit:
                          this.periods === "H1" || this.periods === "H4" || this.periods === "H2"
                            ? "hour"
                            : this.periods === "D1"
                              ? "day"
                              : "minute",
                      },
                      labels: dataSet.map((item) => item.x),
                      ticks: {
                        maxTicksLimit: 30,
                        ...(this.periods !== "D1" && {
                          stepSize:
                            this.periods === "H1"
                              ? 1
                              : this.periods === "H4"
                                ? 4
                                : 2,
                        }),
                        callback: function (value) {
                          const date = new Date(value);
                          if (periods === "D1") {
                            return date.toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                            });
                          } else {
                            return date.toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                            });
                          }
                        },
                      },
                      stacked: true,
                    },
                    "y-line": {
                      id: "y-line",
                      position: "right",
                      grid: {
                        drawOnChartArea: false,
                      },
                      beginAtZero: false,
                      min: startValue,
                      ticks: {
                        callback: function (value) {
                          return value.toFixed(2);
                        },
                      },
                    },
                    y: {
                      display: false,
                      grid: {
                        display: false,
                      },
                    },
                  },
                },
              });
            }
          });
        this.fourChartIds
          .slice(0, this.totalChart)
          .forEach((chartId, index) => {
            const canvas = document.getElementById(chartId);
            if (canvas) {
              const ctx = canvas.getContext("2d");
              if (this.fourCharts[index]) {
                this.fourCharts[index].destroy();
              }

              const dataSet = this.fourChartDataSets[index] || [];
              const minValue = Math.min(
                ...dataSet.map((item) => item.longShortRatio)
              );
              const roundedValue = Math.floor(minValue / 0.05) * 0.05;
              const startValue =
                minValue - roundedValue <= 0.05
                  ? roundedValue - 0.5
                  : roundedValue + 0.5;
              this.fourCharts[index] = new ChartJS(ctx, {
                type: "bar",
                data: {
                  datasets: [
                    {
                      label: "Long holding / Short holding",
                      type: "line",
                      data: dataSet,
                      borderColor: "rgba(0, 0, 0, 1)",
                      pointBackgroundColor: "rgba(255, 255, 255, 1)",
                      pointBorderColor: "rgba(0, 0, 0, 1)",
                      pointBorderWidth: 1.5,
                      pointRadius: 4,
                      pointHoverRadius: 6,
                      fill: true,
                      tension: 0.4,
                      borderWidth: 2,
                      yAxisID: "y-line",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Long holding ratio",
                      data: dataSet.map((item) => item.longPercent),
                      backgroundColor: "rgba(0, 209, 112, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Short holding ratio",
                      data: dataSet.map((item) => item.shortPercent),
                      backgroundColor: "rgba(231, 76, 60, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                    },
                    datalabels: {
                      display: false
                    },
                    title: {
                      display: true,
                      text: `Elite Account Long-Short Position Holders Ratio(${this.coinNames[index]})`,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const dataset = tooltipItem.dataset;
                          const rawData = dataset.meta.customValue;
                          const index = tooltipItem.dataIndex;
                          const shortPercent = rawData[index].shortPercent || 0;
                          const longPercent = rawData[index].longPercent || 0;
                          const longShortRatio =
                            rawData[index].longShortRatio || 0;
                          return `Long: ${longPercent}%\r\nShort: ${shortPercent}%\r\nLong Short Ratio: ${longShortRatio}`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        ...(this.periods === "D1" && {
                          round: "day",
                        }),
                        unit:
                          this.periods === "H1" || this.periods === "H4" || this.periods === "H2"
                            ? "hour"
                            : this.periods === "D1"
                              ? "day"
                              : "minute",
                      },
                      labels: dataSet.map((item) => item.x),
                      ticks: {
                        maxTicksLimit: 30,
                        ...(this.periods !== "D1" && {
                          stepSize:
                            this.periods === "H1"
                              ? 1
                              : this.periods === "H4"
                                ? 4
                                : 2,
                        }),
                        callback: function (value) {
                          const date = new Date(value);
                          if (periods === "D1") {
                            return date.toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                            });
                          } else {
                            return date.toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                            });
                          }
                        },
                      },
                      stacked: true,
                    },
                    "y-line": {
                      id: "y-line",
                      position: "right",
                      grid: {
                        drawOnChartArea: false,
                      },
                      beginAtZero: false,
                      min: startValue,
                      ticks: {
                        callback: function (value) {
                          return value.toFixed(2);
                        },
                      },
                    },
                    y: {
                      display: false,
                      grid: {
                        display: false,
                      },
                    },
                  },
                },
              });
            }
          });
      });
    },
  },
};
</script>

<style scoped>
.form-group-horizontal {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-form select,
.search-button button {
  padding: 8px 12px;
  font-size: 14px;
}

.input-form {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.input-form:hover,
.select-point:hover {
  cursor: pointer;
}

.input-group {
  margin-bottom: 10px;
}

.input-group input {
  width: 80%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.select-form {
  margin-bottom: 10px;
  width: 120px;
}

select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  width: 100%;
  max-width: 200px;
  margin-top: 10px;
}

button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

.chart-grid {
  margin: 0 auto;
  width: 100%;
}

.chart-grid .chart-box {
  border: 2px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.chart-container {
  flex: 1 1 calc(50% - 40px);
  max-width: calc(50% - 40px);
  min-width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-chart,
.second-chart,
.third-chart,
.four-chart {
  width: 100%;
}

.chart-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.chart-header h3 {
  font-size: 20px;
  margin: 0;
  color: #333;
}
</style>
