<template>
  <div class="favorite-coins">
    <h1>Binance Coins</h1>
    <div class="search-container">
      <input 
        type="text" 
        v-model="searchQuery" 
        @input="debouncedSearch" 
        placeholder="Search by coin name..." 
      />
    </div>
    <div class="table-container">
      <table class="coins-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Coin Name</th>
            <th>Favorite</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(coin, index) in filteredCoins" :key="coin.id">
            <td>{{ index + 1 }}</td>
            <td>{{ coin.coinName }}</td>
            <td>
              <input type="checkbox" v-model="coin.favoriteFlag" :true-value="1" :false-value="0" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Nút Submit để gửi danh sách coin yêu thích -->
    <button @click="submitFavorites">Submit</button>
  </div>
</template>

<script>
import { API_URL } from "../config";
import debounce from "lodash/debounce";  // Import lodash debounce

export default {
  data() {
    return {
      coins: [],
      searchQuery: "",  // Từ khóa tìm kiếm
      filteredCoins: [],  // Kết quả tìm kiếm (danh sách coin lọc)
    };
  },
  async created() {
    await this.fetchCoins();
    this.filteredCoins = this.coins;  // Đặt giá trị ban đầu cho filteredCoins là toàn bộ coin
  },
  methods: {
    // Fetch danh sách coin từ API
    async fetchCoins() {
      try {
        const response = await fetch(`${API_URL}/contract/get-favorite`);
        const data = await response.json();
        this.coins = data;
        this.filteredCoins = data;  // Đặt giá trị ban đầu cho filteredCoins
      } catch (error) {
        console.error("Error fetching coins:", error);
      }
    },

    // Hàm tìm kiếm được debounce
    debouncedSearch: debounce(function () {
      // Thực hiện lọc theo từ khóa tìm kiếm
      this.filteredCoins = this.coins.filter(coin => 
        coin.coinName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }, 100),  // Trì hoãn 3 giây (3000ms) trước khi thực hiện tìm kiếm

    // Hàm gửi danh sách coin yêu thích
    async submitFavorites() {
      try {
        const requestBody = this.coins.map(coin => ({
          coinName: coin.coinName,
          favoriteFlag: coin.favoriteFlag ?? 0,
        }));

        const response = await fetch(`${API_URL}/contract/update-favorite`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        console.log(data);
        this.$toast.open({
          message: 'Favorites updated successfully!',
          type: 'success',
          position: 'top-right',
        });
      } catch (error) {
        this.$toast.open({
          message: 'Error updating favorites!',
          type: 'error',
          position: 'top-right',
        });
      }
    },
  },
};
</script>

<style scoped>
.search-container input {
  width: 50%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}
.favorite-coins {
  margin: 20px;
}

.search-container {
  margin-bottom: 20px;
}

.table-container {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.coins-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.coins-table th,
.coins-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

.coins-table thead th {
  position: sticky;
  top: 0;
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  z-index: 1;
}

.coins-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.coins-table tbody tr:hover {
  background-color: #ddd;
}

.coins-table input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
</style>
